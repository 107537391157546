import { IUser, IUpdateUser } from '../../protocolos';
import { Environment } from '../../environments';
import { Api } from '../api/axios-config';

type TUserDataCount = {
    data: IUser[];
    totalCount: number;
}

type TUserWithPointsByEstablishmentId = {
    data: {
        id: string,
        name: string,
        Points: { id: string, score: number }
    }[]
    totalCount: number
}

const listAll = async (sort: string, page: number, order: string, like?: string): Promise<TUserDataCount | Error> => {
    try {
        const url = `/user?limit=${Environment.LIMIT}&skip=${Environment.SKIP}&sort=${sort}&like=${like ? like : ""}&page=${page}&order=${order}`;
        const { data } = await Api.get(url, {});
        if (data) {
            return {
                data: data.data,
                totalCount: data.count
            };
        }
        return new Error('Erro ao listar os registros.');
    } catch (error) {
        console.error(error);
        return new Error((error as { message: string }).message || 'Erro ao listar os registros.');
    }
};

const listAllWithoutPointsByEstablishment = async (establishmentId: string, sort: string, page: number, order: string, like?: string): Promise<TUserDataCount | Error> => {
    try {
        const url = `/user/establishment/${establishmentId}/points/?limit=${Environment.LIMIT}&skip=${Environment.SKIP}&sort=${sort}&like=${like ? like : ""}&page=${page}&order=${order}`;
        const { data } = await Api.get(url, {});
        if (data) {
            return {
                data: data.data,
                totalCount: data.count
            };
        }
        return new Error('Erro ao listar os registros.');
    } catch (error) {
        console.error(error);
        return new Error((error as { message: string }).message || 'Erro ao listar os registros.');
    }
};

const listAllWithPointsByEstablishmentId = async (establishmentId: string, sort: string, page: number, order: string, like?: string): Promise<TUserWithPointsByEstablishmentId | Error> => {
    try {
        const url = `/user/points/establishment/${establishmentId}?limit=${Environment.LIMIT}&skip=${Environment.SKIP}&sort=${sort}&like=${like ? like : ""}&page=${page}&order=${order}`;
        const { data } = await Api.get(url, {});
        if (data) {
            return {
                data: data.data,
                totalCount: data.count
            };
        }
        return new Error('Erro ao listar os registros.');
    } catch (error) {
        console.error(error);
        return new Error((error as { message: string }).message || 'Erro ao listar os registros.');
    }
};

const getById = async (id: string): Promise<IUser | Error> => {
    try {
        const { data } = await Api.get(`/user/${id}`, {});

        if (data) {
            return data;
        }

        return new Error('Erro ao consultar o registro.');
    } catch (error) {
        console.error(error);
        return new Error((error as { message: string }).message || 'Erro ao consultar o registro.');
    }
};

const create = async (dados: Omit<IUser, 'id'>): Promise<IUser | Error> => {
    try {

        const { data } = await Api.post<IUser>('/user', dados, {});

        if (data) {
            return data;
        }

        return new Error('Erro ao criar o registro.');
    } catch (error) {
        console.error(error);
        return new Error((error as { message: string }).message || 'Erro ao criar o registro.');
    }
};

const update = async (id: string, dados: IUpdateUser): Promise<void | Error> => {
    try {
        await Api.put(`/user/${id}`, dados, {});
    } catch (error) {
        console.error(error);
        return new Error((error as { message: string }).message || 'Erro ao atualizar o registro.');
    }
};

const sendEmailForgotPassword = async (to: string): Promise<void | Error> => {
    try {
        await Api.post(`/user/forgotPassword/send`, { "to": to }, {});
    } catch (error) {
        console.error(error);
        return new Error((error as { message: string }).message || 'Erro ao atualizar o registro.');
    }
};

const deleteById = async (id: string): Promise<void | Error> => {
    try {
        await Api.delete(`/user/${id}`, {});
    } catch (error) {
        console.error(error);
        return new Error((error as { message: string }).message || 'Erro ao apagar o registro.');
    }
};

export const UserService = {
    listAll,
    listAllWithoutPointsByEstablishment,
    listAllWithPointsByEstablishmentId,
    deleteById,
    getById,
    update,
    create,
    sendEmailForgotPassword
};